import type {PickKeys} from "ts-essentials";
import {Role} from "@co-common-libs/resources";
import {getCurrentRole} from "@co-frontend-libs/redux";
import React from "react";
import {useSelector} from "react-redux";

interface RoleBasedViewProps {
  children: React.ReactNode;
  roles: PickKeys<Role, boolean>[];
}

export function RoleBasedView({children, roles}: RoleBasedViewProps): React.ReactNode | null {
  const currentRole = useSelector(getCurrentRole);
  if (currentRole && roles.some((role) => currentRole[role])) {
    return children;
  } else {
    return null;
  }
}
