import {ProductUrl} from "@co-common-libs/resources";
import {getProductString} from "@co-common-libs/resources-utils";
import {actions, getCustomerSettings, getProductLookup} from "@co-frontend-libs/redux";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import React, {forwardRef, Ref, useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {FixedPriceProps} from "./fixed-price-input";

export const FixedPriceProductInput = forwardRef(function FixedPriceProductInput(
  {disabled, order}: FixedPriceProps,
  ref: Ref<HTMLButtonElement>,
): React.JSX.Element | null {
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const productLookup = useSelector(getProductLookup);
  const fixedPriceProductUrls = (customerSettings.fixedPriceProductUrls || []) as ProductUrl[];

  const handleFixedPriceItemChanged = useCallback(
    (event: React.ChangeEvent<{value: unknown}>) => {
      const url = event.target.value as ProductUrl;
      dispatch(actions.updateDiff({fixedPriceProduct: url || null}, order));
      if (!url) {
        dispatch(actions.updateDiff({fixedPrice: null}, order));
      }
    },
    [dispatch, order],
  );

  if (!fixedPriceProductUrls) {
    return null;
  }

  return (
    <InputLabel id="fixed-price-product-select" style={{marginRight: "24px", width: "100%"}}>
      <FormattedMessage defaultMessage="Fastpris vare" />
      <Select
        disabled={disabled}
        fullWidth
        labelId="fixed-price-product-select"
        onChange={handleFixedPriceItemChanged}
        ref={ref}
        value={order.fixedPriceProduct || ""}
      >
        <MenuItem key="no-fixed-price-product" value="">
          <FormattedMessage defaultMessage="(Ikke valgt)" />
        </MenuItem>
        {fixedPriceProductUrls.map((productUrl) => (
          <MenuItem key={productUrl} value={productUrl}>
            {getProductString(productLookup(productUrl))}
          </MenuItem>
        ))}
      </Select>
    </InputLabel>
  );
});
