import {Task} from "@co-common-libs/resources";
import {
  getExtendedCustomerSettings,
  getOrderLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {isFixedPriceOrder} from "@co-frontend-libs/utils";
import _ from "lodash";
import {useCallback} from "react";
import {useSelector} from "react-redux";

export function useOrderValidationRequiredForTask(): (
  task: Pick<Task, "order" | "url" | "workType">,
) => boolean {
  const {
    transferTask: {rules: transferTaskRules},
  } = useSelector(getExtendedCustomerSettings);

  const orderLookup = useSelector(getOrderLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  return useCallback(
    (task: Pick<Task, "order" | "url" | "workType">): boolean => {
      if (transferTaskRules.requireOrderApproval) {
        return true;
      } else if (!_.isEmpty(transferTaskRules.requireOrderApprovalForWorkTypes)) {
        if (!task.order || !task.workType) {
          return false;
        }

        const order = orderLookup(task.order);
        const workType = workTypeLookup(task.workType);

        return (
          !order?.routePlan &&
          !!(
            workType &&
            transferTaskRules.requireOrderApprovalForWorkTypes.includes(workType.identifier)
          )
        );
      } else if (task.order) {
        const order = orderLookup(task.order);
        return isFixedPriceOrder(order);
      } else {
        return false;
      }
    },
    [
      orderLookup,
      transferTaskRules.requireOrderApproval,
      transferTaskRules.requireOrderApprovalForWorkTypes,
      workTypeLookup,
    ],
  );
}
