import {Order, ProductUrl} from "@co-common-libs/resources";
import {getCustomerSettings, useUpdatePropertyOnChange} from "@co-frontend-libs/redux";
import React, {forwardRef, Ref} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {DecimalField} from "../decimal-field";

export interface FixedPriceProps {
  disabled?: boolean;
  order: Order;
}

export const FixedPriceInput = forwardRef(function FixedPrice(
  {disabled, order}: FixedPriceProps,
  ref: Ref<HTMLDivElement>,
): React.JSX.Element | null {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const fixedPriceProductUrls = (customerSettings.fixedPriceProductUrls || []) as ProductUrl[];
  const handleFixedPriceChanged = useUpdatePropertyOnChange(order, "fixedPrice");

  if (!fixedPriceProductUrls) {
    return null;
  }

  return (
    <DecimalField
      disabled={disabled || !order.fixedPriceProduct}
      fullWidth
      label={intl.formatMessage({defaultMessage: "Fastpris"})}
      maxDigits={15}
      onChange={handleFixedPriceChanged}
      ref={ref}
      value={order.fixedPrice}
      variant="outlined"
    />
  );
});
