import {ProductUrl} from "@co-common-libs/resources";
import {getProductString} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {MultipleProductDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getProductArray,
  getSettingsEntryLookupByIdentifier,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectAllProducts(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const products: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);
  const productArray = useSelector(getProductArray);
  const unitLookup = useSelector(getUnitLookup);
  const productInstances = useMemo(() => {
    return products
      .map((identifier) => productArray.find((m) => m.url === identifier))
      .filter(notUndefined);
  }, [products, productArray]);

  const productNames = useMemo(() => {
    const names = productInstances.map((product) => getProductString(product));
    names.sort();
    return names;
  }, [productInstances]);

  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const setProductDialogOpenTrue = useCallWithTrue(setProductDialogOpen);
  const setProductDialogOpenFalse = useCallWithFalse(setProductDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleProductDialogOk = useCallback(
    (urls: ReadonlySet<ProductUrl>) => {
      setProductDialogOpen(false);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: [...urls]},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry],
  );

  return (
    <>
      <DisplaySelected
        currentValue={productNames}
        onClick={setProductDialogOpenTrue}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
      />
      <MultipleProductDialog
        materialUseAlternativeText={false}
        onCancel={setProductDialogOpenFalse}
        onOk={handleProductDialogOk}
        open={productDialogOpen}
        productArray={productArray}
        selected={new Set(productInstances.map((product) => product?.url))}
        unitLookup={unitLookup}
      />
    </>
  );
}
