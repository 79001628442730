import type {Config} from "@co-common-libs/config";
import {
  Machine,
  PriceItem,
  Product,
  Project,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {HOUR_MINUTES} from "@co-common-libs/utils";
import {getUnitString} from "./unit";

export const getProjectString = (project?: Project): string => {
  if (!project) {
    return "";
  }
  const {name, projectNumber} = project;
  return name && projectNumber ? `${projectNumber}: ${name}` : projectNumber || name;
};

export const getMachineString = (machine?: Pick<Machine, "c5_machine" | "name">): string => {
  if (!machine) {
    return "";
  }
  const machineName = machine.name;
  const machineID = machine.c5_machine;
  return machineName + (machineID ? ` (${machineID})` : "");
};

export const getWorkTypeString = (
  workType?: Pick<WorkType, "identifier" | "name">,
  includeIdentifier?: boolean,
): string => {
  if (workType) {
    const workTypeIdentifier = includeIdentifier !== false ? workType.identifier : "";
    const workTypeName = workType.name;
    if (workTypeIdentifier) {
      return `${workTypeIdentifier}: ${workTypeName}`;
    } else {
      return workTypeName;
    }
  } else {
    return "";
  }
};
export const getProductString = (product?: Pick<Product, "catalogNumber" | "name">): string => {
  if (product) {
    if (product.catalogNumber) {
      return `${product.name} (${product.catalogNumber})`;
    } else {
      return product.name;
    }
  } else {
    return "";
  }
};

export const getMachinesWorkTypeString = (
  machineList: readonly Machine[],
  includeIdentifier?: boolean,
): string => {
  if (!machineList.length) {
    return "";
  }
  let primaryMachine: Machine | undefined;
  if (machineList.length === 1) {
    primaryMachine = machineList[0];
  } else {
    primaryMachine = machineList.find((machine) => !machine.canPull);
    if (!primaryMachine) {
      primaryMachine = machineList.find((machine) => !!machine.selfPropelled);
    }
    if (!primaryMachine) {
      // we know that count > 1, so first element is present
      primaryMachine = machineList[0];
    }
  }
  const identifier = primaryMachine.c5_machine;
  const {name} = primaryMachine;
  if (includeIdentifier == null || includeIdentifier) {
    return `${identifier}: ${name}`;
  } else {
    return `${name}`;
  }
};

export const formatMinutes = (val?: number | null): string => {
  const value = val || 0;
  if (value < 0) {
    return `-${formatMinutes(-value)}`;
  }
  const hours = Math.floor(value / HOUR_MINUTES);
  const minutes = Math.floor(value % HOUR_MINUTES);

  const hoursPadding = hours < 10 ? "0" : "";

  const minutesPadding = minutes < 10 ? "0" : "";
  return `${hoursPadding}${hours}:${minutesPadding}${minutes}`;
};

export const formatDuration = (
  durationFormat: Config["durationFormat"],
  val?: number | null,
): string => {
  const value = val || 0;
  if (value < 0) {
    return `-${formatDuration(durationFormat, -value)}`;
  }
  const hours = Math.floor(value / HOUR_MINUTES);
  const minutes = Math.floor(value % HOUR_MINUTES);
  if (durationFormat === "<HOURS>h <MINUTES>m") {
    return `${hours}t ${minutes}m`;
  } else {
    const minutesPadding = minutes < 10 ? "0" : "";
    return `${hours}:${minutesPadding}${minutes}`;
  }
};

export const getPriceItemIdentifierString = (priceItem: PriceItem): string => {
  let identifier = "";
  const remoteUrl = priceItem.remoteUrl as string | null | undefined;
  if (remoteUrl) {
    if (remoteUrl.indexOf("https://restapi.e-conomic.com/products/") === 0) {
      const idStart = 39;
      identifier = remoteUrl.substr(idStart);
    } else {
      identifier = remoteUrl;
    }
  }
  return identifier;
};

export const getPriceItemIdentifierStringWithUnit = (
  priceItem: PriceItem,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): string => {
  const identifier = getPriceItemIdentifierString(priceItem);
  const unit = getUnitString(priceItem, unitLookup);

  return `${unit} - ${identifier}`;
};
