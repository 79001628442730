/**
 * This file was generated from config-schema.json using json-schema-to-typescript.
 * Run ./scripts/rebuild.ts to update.
 */

import type {Config} from "./types";

export const defaultValues: Config = {
  absenceCalendar: false,
  absenceCalendarBlockColor: "rgba(255, 204, 128, 0.2)",
  absenceCalendarDisplayLabel: null,
  absenceTypeColors: {},
  absenceTypeLabels: {},
  absenceTypesAccessibleToEmployees: null,
  absenceTypeShortLabels: {},
  absenceTypesVisibleOnFullscreenAbsenceCalendar: null,
  absenceWarningDisabledFor: [],
  addEditLogLocationSkipsCustomerSelection: true,
  addMaterialNotesToProjectCostActivity: false,
  addNewAgricultureTaskAsDefault: [],
  addNewContractorTaskAsDefault: [],
  addNewContractorTaskAsDefaultGroup: [],
  address: "",
  adjustBilledMinutes: "ROUND_UP_15",
  adminCanCreateCustomerTask: true,
  allowCustomerTaskEmployeeChange: true,
  allowCustomerTaskMachineChange: true,
  allowDuplicateProductUses: false,
  allowMachineOperatorChangeOnTaskInfoPage: false,
  allowMachineOperatorToEditLocationLocationType: true,
  allowMachineOperatorToEditTaskDate: false,
  allowMoreThanTwoMachinesForDepartments: [],
  allowOrderReferenceNumberEdit: true,
  allowSeniorMachineOperatorsToManageFoodBookings: false,
  allowTakingPendingTasksFromOthers: true,
  allowTransportlogAmountMismatch: false,
  alternativeMaterialsMobileLayout: false,
  alwaysAllowEditingOrderReferenceNumber: false,
  alwaysAllowEditingOrderWorkplace: false,
  alwaysAllowManagerTaskAndOrderNotesEdit: false,
  alwaysAllowManagerTaskPhotosEdit: false,
  alwaysAllowMoreThanTwoMachines: false,
  alwaysUseLogTab: false,
  anniversariesEnabled: false,
  askForPriceGroupSelectionAfterWorkTypeSelection: false,
  askRegardingMissingBreakOnExternalTaskCompletion: false,
  askRegardingMissingBreakOnInternalTaskCompletion: false,
  autoAddSprayLogFor: [],
  autoAddTransportLogFor: [],
  autoAddYieldLogFor: [],
  autoComputedSalaryVouchers: true,
  autoCopyMaterialNoteToSupplementingProductNote: false,
  autoCreateLogPrint: false,
  autoFillReferenceNumberWithCustomerAccount: false,
  autoFillUnregisteredMinutesOnStart: null,
  autoInsertOrderDateAsNewTaskDate: true,
  autoInsertSearchStringAsNewCustomerName: true,
  autoSupplementingProducts: null,
  availabilityWeekdays: null,
  availabilityWeekdaysAskFromDay: 1,
  availabilityWeekdaysAskFromHour: 12,
  availabilityWeekends: false,
  availabilityWeekendsAskFromDay: 4,
  backgroundPositionInterval: 0,
  barcodeScannerMachineDialog: null,
  barcodeScannerProductDialog: null,
  billedBreaks: false,
  bookkeepingDayHideBlankPriceItems: false,
  bookkeepingDayHideBlankProducts: false,
  bookkeepingDayLinePerTimer: false,
  bookkeepingDayPDF: false,
  bookkeepingDayRespectNoteLineBreaks: false,
  bookkeepingListColumns: {
    desktop: [
      "employee",
      "employeeName",
      "completed",
      "open",
      "approved",
      "posted",
      "customerCultureProjectWorkplace",
      "date",
    ],
    mobile: [
      "employee",
      "completed",
      "open",
      "approved",
      "posted",
      "customerCultureProjectWorkplaceMobile",
      "dateMobile",
    ],
    tablet: [
      "employee",
      "employeeName",
      "completed",
      "open",
      "approved",
      "posted",
      "customerCultureProjectWorkplace",
      "date",
    ],
  },
  bookkeepingMachineNames: false,
  bookkeepingWorkplaceAddressReplaceName: false,
  bookkeepingWorkTypeAsWorkTypeAndVariant: false,
  brugerdataCreateOrderButton: false,
  brugerdataMaterialProductGroupRanges: null,
  brugerdataPriceitemProductGroupRanges: null,
  brugerdataShowMachineOperatorAlias: false,
  brugerdataSync: false,
  brugerdataSyncOnCompleted: false,
  brugerdataSyncOnValidated: false,
  c5Sync: false,
  calendarForMachineOperators: true,
  calendarPlannedTasksBehindMeasured: false,
  calendarPlanningFunctions: true,
  calendarShowEmployeeAlias: true,
  calendarShowEmployeeName: false,
  calendarShowEmployeeNumber: false,
  calendarShowMaterialsWithUnits: [],
  calendarShowWorkTypeNumber: true,
  canAddProject: null,
  canChangeTransportLogTime: true,
  canCreateCultures: false,
  canCreateExternalWorkType: false,
  canCreateMachines: false,
  canCreatePriceGroups: false,
  canCreatePriceItems: false,
  canEditAllUserDataOnProfilePage: false,
  canEditCultures: false,
  canEditCustomerVATNumber: false,
  canEditExternalWorkType: false,
  canEditInternalWorkTypes: false,
  canEditMachines: false,
  canEditPriceGroups: false,
  canEditPriceItemActive: false,
  canEditPriceItemBillable: false,
  canEditPriceItemID: false,
  canEditPriceItemInputConditions: false,
  canEditPriceItemName: false,
  canEditPriceItemOnlyVisibleOnOverview: false,
  canEditPriceItemProduct: false,
  canEditPriceItemRelevantForExecution: false,
  canEditPriceItems: false,
  canEditPriceItemUnit: false,
  canEditPriceItemUseManualDistribution: false,
  canEditProductGroups: false,
  canEditProducts: false,
  canEditProjectDistance: false,
  canEditProjects: false,
  canEditUnits: false,
  checkoutHideInactive: false,
  chooseWorkTypesOnCustomerJournal: true,
  colorInternalTaskWorkTypesOnEmployeeCalendar: false,
  colorUnscheduledCalendar: false,
  colorWorkTypesOnEmployeeCalendar: false,
  companyName: "CustomOffice",
  completeTaskAsInternal: true,
  completeTaskAsInternalTargetDate: "NEXT_DAY",
  computedSalaryVoucherStartDay: 1,
  concurrentTasksAllowed: false,
  concurrentTasksAllowedForDepartments: [],
  concurrentTasksAllowedForWorkTypes: [],
  consultantNotes: "",
  contactMail: "",
  contactPerson: "",
  contactPhone: "",
  contactsMayHaveCellphone: true,
  continuationCopyFields: [
    "workType",
    "priceGroup",
    "department",
    "relatedWorkplace",
    "relatedPickupLocation",
    "machineOperator",
    "machineGroup",
    "order",
    "date",
    "minutesExpectedTotalTaskDuration",
    "notesFromManager",
    "notesFromMachineOperator",
    "machineuseSet",
    "workshopchecklistanswerSet",
    "fielduseSet",
    "priority",
    "billedPreparation",
    "billedTransportation",
    "reportingSpecification",
    "reportingLocations",
    "invoiceNote",
    "project",
    "referenceNumber",
  ],
  continuationTaskTargetDate: "NEXT_DAY",
  conversionFieldDecimals: 2,
  createCostLines: false,
  createCustomer: null,
  createSalesLines: false,
  customerAliasEdit: false,
  customerApprovalGate: "TASK_BOOKKEEPING",
  customerCreateInvoicesSetup: false,
  customerFields: true,
  customerFileUpload: true,
  customerJournal: true,
  customerOrderListNewestFirst: false,
  customerOrderOverview: true,
  customerProjectManager: false,
  customerRequiredFields: [],
  customerStatsVisibleToAll: false,
  customerTaskAutoWorkTypePreviousMachines: true,
  customerTaskCreationWizard: false,
  customOfficeProductVariant: "STANDARD",
  cvr: "",
  dayEndRounding: null,
  daysAbsenceTypes: ["daysOffWithoutPay", "vacation", "sickLeave", "floatingHoliday"],
  daysIntoTheFutureVisibleToMachineOperator: 14,
  dayStartRounding: null,
  defaultTaskEmployee: null,
  departmentExtraTimers: {},
  departments: {},
  dinnerBookingLocations: [],
  dinnerBookings: null,
  dinnerBookingsShowThumbDialog: false,
  disabledMachines: [],
  disabledWorkTypes: [],
  displayCustomerContacts: false,
  displayProjectsPageMenu: "SETTINGS",
  distributionTableAdjustTimeMethod: "NO_ADJUSTMENT",
  dontShowTransportLogDialog: false,
  durationFormat: "<HOURS>h <MINUTES>m",
  economicAppendMaterialNotesToInvoiceLineDescription: false,
  economicAutogeneratedCustomerNumbers: false,
  economicCompanyIdentificationNumber: "",
  economicCustomerDetailsOnTransportlogOnInvoice: false,
  economicCustomerGroup: 1,
  economicDefaultPaymentTerms: null,
  economicEnableMachinesDepartmentSync: false,
  economicEnableMachinesProjectEmployeesSync: false,
  economicEnableProjectActivitiesImport: false,
  economicEnableUploadUsersToProjectEmployees: false,
  economicEnableWorkplaceImport: false,
  economicEnableWorkTypeFromProductGroupImport: false,
  economicFuelSurchargeLinesAtBottom: true,
  economicInvoiceEmployeeNotes: true,
  economicInvoiceIncludeTotalTaskTime: false,
  economicInvoiceManagerNotes: false,
  economicInvoicePerOrderReferenceNumber: false,
  economicInvoicePerProject: false,
  economicInvoicePerWorkplace: false,
  economicInvoicePerWorkplacePickupLocationFallback: false,
  economicInvoiceProjectNumberAndNameAsOtherReference: false,
  economicInvoicePullerAsDeparment: false,
  economicInvoicesForProjectTasks: true,
  economicInvoiceShowOrderWorkplaceNameAsOtherRef: false,
  economicInvoiceShowTaskWorkplaceNameAsOtherRef: false,
  economicInvoiceWorkplaceAddressReplaceName: false,
  economicMaterialProductGroups: [],
  economicMergLinesPerOrder: false,
  economicPrefixCustomerContactNameWith: "",
  economicPrefixMachineOperatorNameOrAliasWith: "Udført af",
  economicPriceitemProductGroupRanges: null,
  economicProjectsApproveTimesOnTransfer: false,
  economicProjectsIncludeNotes: true,
  economicProjectsMachineEmployeeGroups: [],
  economicProjectsMachineEmployeeNumberOffset: 0,
  economicProjectsMachineHoursActivity: null,
  economicProjectsManHoursActivity: null,
  economicProjectsManHoursMachines: [],
  economicProjectsProductUseCostActivity: null,
  economicProjectsProductUseCostEmployee: null,
  economicRouteTaskStartStopOnInvoice: false,
  economicSeperateDatesWithBlankLines: true,
  economicSeperateNoteLines: false,
  economicSeperateTasksWithBlankLines: true,
  economicShowCustomerContactName: false,
  economicShowFieldUse: false,
  economicShowFieldUseCrop: false,
  economicShowGenericTransportlogOnInvoice: [],
  economicShowMachineName: false,
  economicShowMachineOperatorAlias: false,
  economicShowMachineOperatorAliasOrNameWithoutLineBreak: false,
  economicShowMachineOperatorName: false,
  economicShowOrderReferenceNumber: false,
  economicShowPickupLocation: false,
  economicShowTaskReferenceNumber: false,
  economicShowTransportlogOnInvoice: true,
  economicShowWorkPlaceOrDeliveryLocation: false,
  economicSubtractDowntimeIdentifiers: [],
  economicSync: false,
  economicSyncListColumns: ["date", "customer", "machines", "machineOperator", "notes"],
  economicTaskDepartmentImport: false,
  economicUploadMachinesToProjectEmployeeGroup: null,
  economicUploadUsersToProjectEmployeeGroup: null,
  economicUploadWorkPlaces: false,
  economicWorktypeDepartmentImport: null,
  economicZeroBlank: true,
  effectiveTimeLabel: "Effektiv tid",
  emailFromAddress: "noreply@customoffice.dk",
  emailReplyToAddress: "",
  employeeCalendarManualOrderPerUser: false,
  employeeCanAccessAppendicesPage: false,
  employeeLabelVariant: "MACHINEOPERATOR",
  employeeSameDayTasksWarning: true,
  enableAccommodationAllowanceRegistrationOnInternalTasks: false,
  enableAddProducts: true,
  enableAppendicesPage: false,
  enableBackgroundColorTasks: false,
  enableCalendarDepartmentFilter: false,
  enableCalendarTaskClickWhenTaskPopupIsOpen: true,
  enableCalendarTaskInfoPopup: true,
  enableCalendarWorkTypeFilter: true,
  enableConvertInternalToCustomerTask: false,
  enableCustomerCellphone: false,
  enableCustomerContacts: true,
  enableCustomerSwitch: true,
  enableDocuments: false,
  enableDoneAtEstimateField: true,
  enableEmployeeCalendarManualOrder: true,
  enableEmployeeGroups: false,
  enableExternalTaskDepartmentField: false,
  enableExternalTaskWorkTypeAndVariantSwitch: true,
  enableExtraWorkButton: null,
  enableFieldsSettingsPage: false,
  enableGPSList: false,
  enableInternalTaskDepartmentField: false,
  enableInvoiceCorrections: true,
  enableJobberRole: false,
  enableLessorUpload: false,
  enableLocationStorage: false,
  enableMaanssonsSalaryUpload: false,
  enableMachineAnalysis: false,
  enableMachinePriceStructureOverviewSettingsPage: false,
  enableMachineSalaryCostReport: false,
  enableMaterialNoteFields: true,
  enableMechanicRole: false,
  enableNoDepartment: false,
  enableOrderMerge: false,
  enableOrderReferenceNumber: false,
  enableOrders: true,
  enableOrderTaskDepartmentFilter: false,
  enableOrderTaskWorkTypeFilter: true,
  enablePriceItemMinimumCount: false,
  enablePriceItemTransferAsMinutes: false,
  enablePriceItemVolumeDiscount: false,
  enableProjectDistance: false,
  enableProjectManagerRole: false,
  enableProjects: false,
  enableProjectTravelTime: false,
  enableRecentlyUsedProducts: true,
  enableReportGenerator: false,
  enableRouteJournal: false,
  enableSmallMachines: false,
  enableStandardMachinesSettingsPage: false,
  enableStandardProductsSettingsPage: false,
  enableTaskEstimation: true,
  enableTaskInternalManagerNotes: false,
  enableTaskLogLocationChange: true,
  enableTaskReferenceNumber: false,
  enableTimeOverviewPage: true,
  enableTransferTasks: false,
  enableTransportLog: false,
  enableTransportLogStatistics: false,
  enableTransportReport: false,
  enableUnitSettingsPage: false,
  enableUnscheduledCalendar: true,
  enableWorkshopChecklistsFor: [],
  enableWorkTypePriceStructureOverviewSettingsPage: false,
  enableWorkTypeSettingsPage: false,
  errorOnOnlyPuller: true,
  expectedAmountEnabled: false,
  expectedAmountVisibleToMachineOperators: false,
  externalTaskCulture: false,
  externalTaskCustomer: true,
  externalTaskWithoutPriceLinesLegal: false,
  externalTaskWithoutTaskWorkTypeVariantLegal: false,
  extraWorkButtonCopiesProject: false,
  fieldDefaultLocationType: null,
  fieldDialogOrFiltering: false,
  fixedPriceProductUrls: [],
  foodBookingNotificationTime: null,
  fuelSurcharge: null,
  fullscreenCalendarSlideshow: true,
  fullscreenCalendarSlideshowSeconds: 120,
  geolocation: {
    activeMarkerWarningThresholdInMinutes: 60,
    enabled: true,
    homeMarkerLatitude: 0,
    homeMarkerLongitude: 0,
    initialPositionLatitude: 0,
    initialPositionLongitude: 0,
    initialZoom: 12,
    registerPositionOnTimerClick: true,
  },
  googleMapsAPIKey: "",
  googleMapsCountryRestrictions: ["dk"],
  hideCompleteAndMakeCopyButton: false,
  hideCultureMenuFromMachineOperators: false,
  hideCustomerMenuFromMachineOperators: false,
  hideInternalTaskListWorkTypes: [],
  hideOrderCalendarTab: false,
  hidePrimaryTimeButtonForExternalWorktypes: [],
  hidePrimaryTimeButtonForInternalWorktypes: [],
  hideTasklistBillingTab: false,
  hideUnscheduledCalendarForMachineOperators: false,
  hideUnscheduledWorkshopTasks: false,
  holidaysVisibleToMachineOperators: false,
  hoursAbsenceTypes: ["compensatory"],
  ifCountZeroRequireCorrectedCountZero: false,
  importAllFieldsPerVatNumber: false,
  importExternalWorkTypesFromProductGroups: false,
  importInternalWorkTypesFromProducts: false,
  includeTaskCancel: false,
  includeTaskCopy: true,
  includeTransportlogOverviewVariant: false,
  industries: [],
  informationPageEnabled: true,
  internalTaskAutoWorkTypePreviousMachines: false,
  internalTaskPhotos: false,
  internalTimeLabel: "Intern",
  invoiceAddGenericTransportLogs: [],
  invoiceAddTransportLogSum: true,
  invoiceAllowRecordedWithoutInvoiceLines: false,
  invoiceExcludeProducts: [],
  invoiceExtraEffectiveTimerIdentifiers: [],
  invoiceNoteAddAdminNotes: false,
  invoiceNoteAddCustomerContactName: false,
  invoiceNoteAddCustomerDetailsOnTransportLog: false,
  invoiceNoteAddEmployeeAlias: false,
  invoiceNoteAddEmployeeName: false,
  invoiceNoteAddEmployeeNotes: true,
  invoiceNoteAddFieldUse: false,
  invoiceNoteAddFieldUseCrop: false,
  invoiceNoteAddMachineName: false,
  invoiceNoteAddManagerNotes: false,
  invoiceNoteAddOrderManagerNotes: false,
  invoiceNoteAddOrderReferenceNumber: false,
  invoiceNoteAddPickupLocation: false,
  invoiceNoteAddRouteTaskStartStop: false,
  invoiceNoteAddTaskReferenceNumber: false,
  invoiceNoteAddTotalTaskTime: false,
  invoiceNoteAddWorkPlaceOrDeliveryLocation: false,
  invoiceNotePrefixCustomerContactNameWith: "Kontakt",
  invoiceNotePrefixEmployeeNameOrAliasWith: "Udført af",
  invoicesAddTaskNotesToEffectiveTimeLine: true,
  invoiceSplitBy: "NONE",
  invoiceSubtractDowntimeTimers: [],
  invoiceTaskNotesToEffectiveTimeLine: true,
  invoiceUseWorkplaceAddressAsName: true,
  jobberRoleCanCreateInternalTasks: false,
  locationCrossCustomerSelectionEnabled: false,
  locationFavoritesEnabled: false,
  locationStorageJournalStatusColumn: false,
  locationStoragePageVisibleToEveryone: false,
  logButtonText: "NAME",
  logDeliveryButtonDisplayCount: false,
  logEmailText: "",
  logPickupButtonDisplayCount: false,
  logRecipients: [],
  logWorkplaceDisplayCount: false,
  lunchBookingLocations: [],
  lunchBookings: false,
  machineAnalysisAccounts: {},
  machineBeforeMachineOperator: false,
  machineCalendar: false,
  machineCalendarForMachineOperators: false,
  machineExtraTimers: {},
  machineLabelVariant: "MACHINE",
  machineOperaterCanEditInvoiceNote: false,
  machineOperatorCanCreateCustomerTask: true,
  machineOperatorCanDeleteAssignedTasks: true,
  machineOperatorMayAddTaskPhotosAfterCompletion: false,
  machineOperatorsCanChooseProject: false,
  machineOperatorsCanOnlySeeTheirOwnTasks: false,
  machineOperatorsCanOnlySeeThemselvesOnCalendar: false,
  machineOperatorsCanSeeAbsenceCalendar: false,
  machineOperatorsCanSeeTheirOwnTasksAndActiveTasks: false,
  machinePriceGroupExtraTimers: {},
  managerMenuTasksAfterEmployees: false,
  mapTaskFormat: "<DAY>.<MONTH> - <EMPLOYEE ALIAS>: <WORK TYPE NAME>",
  mapVisibleToAll: false,
  mapVisibleToJobberRole: true,
  materialDecimals: 2,
  materialIssuesErrors: true,
  materialUseAlternativeText: false,
  materialZeroCausesError: [],
  missingEffectiveTimeIsError: true,
  navImportCopyCustomerAccountToPhone: false,
  navImportProjects: false,
  navSync: false,
  navSyncProfile: null,
  navSyncUploadEnabled: false,
  navTimersToTransferWithoutMachines: [],
  navTransferEmployeeNotes: true,
  navVariantTypeImports: [],
  navWorktypeRanges: null,
  noExternalTaskWorkType: false,
  noProducts: false,
  numberOfSalaryVouchersVisibleToEmployees: null,
  oldTaskWarningAgeMinutes: 1440,
  oneDayLessVisibleToMachineOperatorsBefore: "00:00",
  onlyAdminCanChangeDepartment: false,
  onlyAllowEditingNameOnPriceGroupSettingsPage: false,
  onlyEnableProjectsForDepartments: [],
  onlyManagerCreatedTasksCalendarPlanned: false,
  onlyManagersCanRegisterAbsence: false,
  onlyShowCalledInDialogForWorktypes: [],
  onlyWeekdaysAllowedAbsenceTypes: [],
  orderCalendar: true,
  orderCalendarAsTaskCalendar: false,
  orderCalendarAsTaskCalendarBlockExtraInfo: "phone",
  orderCalendarBlockExtraInfo: "phone",
  orderCalendarFoldAllDefault: false,
  orderCalendarMachineLinebreak: false,
  orderCalendarShowCreatedDate: true,
  orderCalendarShowOrderNoteLines: 0,
  orderCopyFields: ["customer", "draft"],
  orderDrafts: false,
  orderDraftsAllwaysCreateDraft: true,
  orderDraftsStartOnDraft: true,
  orderEntryAutoOpenCustomerSelection: false,
  orderEntryResultsEffective: false,
  orderEntryResultsWorkplace: true,
  orderEntryShowContact: true,
  orderEntryShowPriceGroup: true,
  orderEntryShowResults: true,
  orderEntryShowWorkPlace: true,
  orderEntryTaskTableAllwaysShowMachinePriceGroups: false,
  orderEntryTaskTime: false,
  orderListColumns: {
    archive: {
      employee: {
        desktop: ["merge", "note", "workType", "employees", "date"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date"],
      },
      manager: {
        desktop: ["merge", "note", "workType", "employees", "date", "status"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date", "status"],
      },
    },
    drafts: {
      employee: {
        desktop: ["merge", "note", "workType", "employees", "date"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date"],
      },
      manager: {
        desktop: ["merge", "note", "workType", "employees", "date", "status"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date", "status"],
      },
    },
    open: {
      employee: {
        desktop: ["merge", "note", "workType", "employees", "date"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date"],
      },
      manager: {
        desktop: ["merge", "note", "workType", "employees", "date", "status"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date", "status"],
      },
    },
    readyForBilling: {
      employee: {
        desktop: ["merge", "note", "workType", "employees", "date"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date"],
      },
      manager: {
        desktop: ["merge", "note", "workType", "employees", "date", "status"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date", "status"],
      },
    },
    validation: {
      employee: {
        desktop: ["merge", "note", "workType", "employees", "date"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date"],
      },
      manager: {
        desktop: ["merge", "note", "workType", "employees", "date", "status"],
        mobile: ["customerShort", "workTypeShort", "dateShort"],
        tablet: ["merge", "note", "workType", "employees", "date", "status"],
      },
    },
  },
  orderListNoteLines: 0,
  orderListPageEnabled: true,
  orderReferenceNumberLabel: null,
  orderShowCustomerFiles: true,
  orderShowOrderFiles: true,
  orderTaskTableSort: "PRIORITY, CREATED",
  orderValidation: false,
  orderValidationForWorkTypes: [],
  overviewShowPrices: true,
  ownCompanyId: null,
  paidDayAbsenceTypes: [],
  periodSplitThresholdMinutes: 120,
  preparationWorkType: null,
  priceGroupHideTimers: {},
  priceItemConversionSetup: false,
  priceItemRelationUnitConversionHelpers: {},
  productImageSelection: false,
  productMultiSelect: false,
  productsExcludedFromInvoice: [],
  projectLabelVariant: "PROJECT",
  projectManagerCanManageAbsence: false,
  projectMissingCompleteError: false,
  projectMissingCompleteWarning: false,
  projectMissingValidateError: true,
  projectMissingValidateWarning: false,
  recordCultureTasks: true,
  recordCustomerTasks: true,
  recordInternalTasks: true,
  registerAbsence: true,
  remunerationAbsenceTypes: [],
  remunerationCalendarDayBonusLabels: [],
  remunerationCountBonusLabels: [],
  remunerationDefaultGroup: "default",
  remunerationGroups: {
    default: {
      accumulateCompensatoryLimit: null,
      compensatoryMultiplier: null,
      compensatorySubtractOnly: null,
      extraHalfHolidayAbsenceTypes: [],
      extraHalfHolidays: {},
      extraHolidayAbsenceTypes: [],
      extraHolidays: {},
      halfHolidayHalfThresholds: true,
      halfHolidayHoursRates: null,
      halfHolidaySundayAfterNoon: false,
      hiddenFromGroupSelection: false,
      hoursRates: [[], [], [], [], [], [], []],
      includeCompensatoryOverLimitInPaid: null,
      intervalBonus: [],
      overtimeThresholds: [[], [], [], [], [], [], []],
      overtimeThresholdsReset: false,
      periodCompensatoryLimit: null,
      pools: null,
      reportIgnoreAbsenceOnHolidays: false,
      reportTitle: "Standard",
      specialStartRateMinutes: null,
      workDayBonus: [],
    },
  },
  remunerationIncludeEmptyDatesInReport: false,
  remunerationIntervalBonusLabels: [],
  remunerationNormalTransportKilometersCountBonusLabel: "",
  remunerationPaidTransportWorkType: null,
  remunerationRateLabels: null,
  remunerationReportPerVariation: false,
  remunerationReportShowAlias: false,
  remunerationReportShowHolidays: true,
  remunerationReportShowProjects: false,
  remunerationReportShowReferenceNumbers: false,
  remunerationStartRateLabel: null,
  remunerationTaskBonusLabels: [],
  remunerationVisibleBonusLabels: null,
  remunerationVisibleRateLabels: null,
  remunerationWorkDayBonusLabels: [],
  repairWorkType: null,
  reportDataIsValidFrom: "",
  reportDepartmentWorkTypeProductiveCombinations: {},
  reportEffectiveTimeLabel: "effektiv tid",
  reportExtraData: [],
  reportExtraNonProductiveWorkTypeNameToLatexNameMap: {},
  reportHourDecimals: 1,
  reportLegendShift: {x: "1.3cm", y: "2cm"},
  reportNonProductiveWorkTypeNameToLatexNameMap: {},
  reportSupportedUnits: null,
  requireEmployeeAssignedToTasksWhenConvertingOrderDrafts: false,
  requireFavoriteMachines: false,
  requireFieldForCultureTasks: false,
  requireWorkplaceIfExists: false,
  rolesMayCreateLocations: [],
  rolesMayEditLocations: [],
  routesEnabled: false,
  routeTaskStartStop: false,
  salaryVouchersVisibleToEmployees: false,
  salaryVouchersVisibleToManagers: false,
  selectInformationvisibleTo: false,
  sendApprovedTransportlogToCustomers: [],
  sendValidatedGenericLogToCustomers: {},
  seniorMachineOperatorCanEditNotesFromManager: false,
  seniorMachineOperatorCanManageOrders: false,
  setLogOnlyLocationOnCreate: true,
  setWorkplaceOnlyLocationOnCreate: true,
  sharedTransportLog: false,
  showAccountOnCustomerList: false,
  showAllProjectsOnAppendicesPage: false,
  showArrivalAtLocationField: false,
  showAttOnCustomerList: true,
  showBrugerdataOrdernumber: false,
  showC5AccountOnTaskInstanceAnList: false,
  showCalledInDialogAfterMinutes: null,
  showColleaguesAtCustomer: true,
  showContinueToNextCustomerButton: false,
  showCreatedTimestampOnInternalTasks: false,
  showDateGroupsOnOrderLists: false,
  showDateGroupsOnTaskLists: false,
  showEmployeesAndTasksLayersToMachineOperators: true,
  showFieldNumbersOnCalendar: false,
  showFieldsColumnOnOrderEntryTaskList: false,
  showFieldsOnBookkeepingTables: false,
  showFieldsOnGeolocationMap: false,
  showGenericPeriodLogView: false,
  showHeaderOnTransportLog: false,
  showInvoiceNote: false,
  showInvoiceNoteOnOrderPdf: false,
  showLandlinePhoneNumberOnTaskInfo: true,
  showLogEmailFieldOnCustomerInstance: false,
  showLogLocationsOnBookkeeping: false,
  showLogPickupAndDeliveryColumnsOnOrderEntryTaskList: false,
  showMachineAsWorktypeForContratorTasks: false,
  showMachineLastUse: false,
  showMachineOperatorInitialsOnTaskInstance: false,
  showMachineOperatorNameOnTaskInstance: false,
  showManagerInternalNotesOnOrderPDF: false,
  showManagerInternalOrderNoteLinesOnOrderCalendar: 0,
  showManagersUnpaidBillWarning: false,
  showMaterialAutoLinesToEmployees: false,
  showNoteOnInvoiceLineTable: false,
  showOrderCreatedDateTime: false,
  showOrderNoteOnTaskList: false,
  showOrderReferenceNumberOnTaskInfoTab: false,
  showOrderWorkplaceOnOrderPdf: false,
  showOursToggle: false,
  showPhotoOnCalendar: false,
  showProductList: false,
  showProjectAlias: false,
  showProjectInsteadOfCustomer: false,
  showProjectNameOnTaskInfoTab: false,
  showProjectNameOnTimeOverviewPage: false,
  showProjectNumberOnTaskInfoTab: false,
  showProjectNumberOnTaskInstance: true,
  showProjectNumberOnTimeOverviewPage: false,
  showProjectOnTaskInstance: false,
  showRealTimeColumnOnOrderPDF: false,
  showSearchnameOnCustomerList: true,
  showStorageOnTaskFieldMap: false,
  showStorageProductNotOnLocationWarning: true,
  showTaskArchiveToMachineOperators: false,
  showTaskEffectiveTimeOnTaskInfoTab: false,
  showTaskInfoManagerNotesAboveLogButtons: false,
  showTaskInvoiceNoteColumnOnOrderEntryTaskList: false,
  showTaskNotesOnOrderEntryPage: false,
  showTaskRefColumnOnOrderEntryTaskList: false,
  showTimeColumnOnOrderEntryTaskList: false,
  showUnscheduledInternalTaskCalendar: true,
  showWhoHasNotReadInformation: false,
  showWorkplaceOnTaskInstance: false,
  showWorkplacesInCustomerPickupDeliveryLocationDialog: true,
  showWorkshopMachineName: false,
  singleDayAbsenceTypes: ["childsFirstSickDay"],
  suggestRecentlyUsedCustomers: false,
  suggestRecentlyUsedWorkTypes: false,
  switchProjectAndCustomerButtonLocation: false,
  syncRecipients: [],
  taskAndOrderDatesFromCalendarOnCreate: true,
  taskCalendarFadeCompletedTasks: false,
  taskCompleteOverview: false,
  taskCompletePrepareQuery: false,
  taskCompleteRepairQuery: true,
  taskCompleteRepairQueryRequireNote: false,
  taskCopyAlwaysOverridemachineOperator: false,
  taskCopyFields: [
    "workType",
    "priceGroup",
    "department",
    "relatedWorkplace",
    "relatedPickupLocation",
    "machineOperator",
    "machineGroup",
    "order",
    "date",
    "minutesExpectedTotalTaskDuration",
    "notesFromManager",
    "notesFromMachineOperator",
    "machineuseSet",
    "workshopchecklistanswerSet",
    "fielduseSet",
    "priority",
    "billedPreparation",
    "billedTransportation",
    "reportingSpecification",
    "reportingLocations",
    "invoiceNote",
    "project",
    "referenceNumber",
  ],
  taskCultureSubheader: true,
  taskInstancePDF: false,
  taskInstancePdfShowWorkplacePickupDelivery: false,
  taskListColumns: {
    archive: {
      employee: {
        desktop: ["workType", "notes", "customerMachine", "machineOperator", "time", "date"],
        mobile: ["workTypeShort", "notes", "customerMachine", "machineOperatorShort", "dateShort"],
        tablet: [
          "workTypeShort",
          "notes",
          "customerMachine",
          "machineOperator",
          "time",
          "dateShort",
        ],
      },
      manager: {
        desktop: [
          "workType",
          "notes",
          "customerMachine",
          "machineOperator",
          "time",
          "date",
          "status",
          "editTask",
        ],
        mobile: [
          "workTypeShort",
          "notes",
          "customerMachine",
          "machineOperatorShort",
          "dateShort",
          "status",
          "editTask",
        ],
        tablet: [
          "workTypeShort",
          "notes",
          "customerMachine",
          "machineOperator",
          "time",
          "dateShort",
          "status",
          "editTask",
        ],
      },
    },
    culture: {
      employee: {
        desktop: ["workType", "customer", "machineOperator", "time", "date", "photo"],
        mobile: ["workTypeShort", "customer", "machineOperatorShort", "dateShort"],
        tablet: ["workTypeShort", "customer", "machineOperator", "time", "dateShort", "photo"],
      },
      manager: {
        desktop: [
          "workType",
          "customer",
          "machineOperator",
          "time",
          "date",
          "photo",
          "status",
          "editTask",
        ],
        mobile: [
          "workTypeShort",
          "customer",
          "machineOperatorShort",
          "dateShort",
          "status",
          "editTask",
        ],
        tablet: [
          "workTypeShort",
          "customer",
          "machineOperator",
          "time",
          "dateShort",
          "photo",
          "status",
          "editTask",
        ],
      },
    },
    customer: {
      employee: {
        desktop: ["workType", "customer", "machineOperator", "time", "date", "photo"],
        mobile: ["workTypeShort", "customer", "machineOperatorShort", "dateShort"],
        tablet: ["workTypeShort", "customer", "machineOperator", "time", "dateShort", "photo"],
      },
      manager: {
        desktop: [
          "workType",
          "customer",
          "machineOperator",
          "time",
          "date",
          "photo",
          "status",
          "editTask",
        ],
        mobile: [
          "workTypeShort",
          "customer",
          "machineOperatorShort",
          "dateShort",
          "status",
          "editTask",
        ],
        tablet: [
          "workTypeShort",
          "customer",
          "machineOperator",
          "time",
          "dateShort",
          "photo",
          "status",
          "editTask",
        ],
      },
    },
    internal: {
      employee: {
        desktop: ["workType", "machines", "machineOperator", "date", "photo"],
        mobile: ["workTypeShort", "machines", "machineOperatorShort", "dateShort"],
        tablet: ["workTypeShort", "machines", "machineOperator", "date", "photo"],
      },
      manager: {
        desktop: ["workType", "machines", "machineOperator", "date", "photo", "status", "editTask"],
        mobile: ["workTypeShort", "machines", "machineOperatorShort", "dateShort", "status"],
        tablet: [
          "workTypeShort",
          "machines",
          "machineOperator",
          "date",
          "photo",
          "status",
          "editTask",
        ],
      },
    },
    own: {
      employee: {
        desktop: ["workType", "customerMachine", "time", "date", "photo"],
        mobile: ["workTypeShort", "customerMachine", "timeShort", "dateShort"],
        tablet: ["workTypeShort", "customerMachine", "time", "dateShort", "photo"],
      },
      manager: {
        desktop: ["workType", "customerMachine", "time", "date", "photo", "status", "editTask"],
        mobile: [
          "workTypeShort",
          "customerMachine",
          "timeShort",
          "dateShort",
          "status",
          "editTask",
        ],
        tablet: [
          "workTypeShort",
          "customerMachine",
          "time",
          "dateShort",
          "photo",
          "status",
          "editTask",
        ],
      },
    },
    readyForBilling: {
      employee: {
        desktop: ["workType", "notes", "customer", "machineOperator", "time", "date", "photo"],
        mobile: ["workTypeShort", "notes", "customer", "machineOperatorShort", "dateShort"],
        tablet: [
          "workTypeShort",
          "notes",
          "customer",
          "machineOperator",
          "time",
          "dateShort",
          "photo",
        ],
      },
      manager: {
        desktop: [
          "workType",
          "notes",
          "customer",
          "machineOperator",
          "time",
          "date",
          "photo",
          "status",
          "editTask",
        ],
        mobile: [
          "workTypeShort",
          "notes",
          "customer",
          "machineOperatorShort",
          "dateShort",
          "status",
          "editTask",
        ],
        tablet: [
          "workTypeShort",
          "notes",
          "customer",
          "machineOperator",
          "time",
          "dateShort",
          "photo",
          "status",
          "editTask",
        ],
      },
    },
  },
  taskListeNoteLines: 0,
  taskListsMachineOperatorHideCompleted: true,
  taskListsMachineOperatorHideCompletedExceptToday: false,
  taskNoteLinesOnTaskOrderCalender: 0,
  taskOrderAutoOpenCustomerSelection: false,
  taskOrderShowContact: true,
  taskOrderShowDate: false,
  taskOrderShowTime: true,
  taskOrderTaskShowWorkPlace: true,
  taskOverlapWarningAfterMinutes: 2,
  taskPageCreatedDate: false,
  taskPickupDelivery: false,
  taskReferenceNumberLabel: null,
  taskShowRelatedFiles: false,
  taskShowRelatedPhotos: false,
  taskShowTimeField: false,
  tasksPrintout: false,
  taskWizardAskForWorkPlace: false,
  taskWizardFieldSelection: false,
  timePriceItemsBeforeOtherPriceItems: false,
  timersContributingToEffectiveTime: [],
  transferOrderAdminNotesToInvoice: false,
  transferOrderNotesToInvoice: false,
  transferTaskRequireOrderApproval: false,
  transferTaskRequireOrderApprovalForWorkTypes: [],
  transferTaskUnApproveOnError: true,
  transportLogDecimals: 2,
  transportLogDistanceKmDecimalPlaces: 0,
  transportlogTimeHeader: true,
  transportLogUnitPerLocation: true,
  transportResponsibleEffectiveTimers: [],
  treatTransportTimeAsEffectiveForDepartments: [],
  unicontaDMEUseTaskProjectForInternalCost: false,
  unicontaDMEWorkingHours: "",
  unicontaMachineDimension: null,
  unicontaManHoursMachines: [],
  unicontaPullerDimension: null,
  unicontaSync: false,
  unicontaVariantDME: false,
  unregisteredBreakAfterMinutes: 15,
  unregisteredWarningAfterMinutes: 2,
  unsetValidatedAndRecordedOnError: true,
  useApproveReport: false,
  useCalendarWorkHours: false,
  useCorrectedCountOnCostLines: false,
  useCustomerAddressAsDefaultTaskAddress: true,
  useEmployeeGroups: false,
  usePunchInOut: false,
  userCellphoneExtra: false,
  userListColumns: {
    employee: {
      desktop: ["alias", "name", "username", "cellphone", "email", "cellphoneIcon", "emailIcon"],
      mobile: ["alias", "name", "cellphoneIcon", "emailIcon"],
      tablet: ["alias", "name", "username", "cellphone", "email", "cellphoneIcon", "emailIcon"],
    },
    manager: {
      desktop: [
        "username",
        "alias",
        "name",
        "cellphone",
        "cellphoneExtra",
        "email",
        "employeeGroup",
        "role",
        "cellphoneIcon",
        "iceWarningIcon",
        "emailIcon",
      ],
      mobile: ["alias", "name", "cellphoneIcon", "emailIcon"],
      tablet: [
        "username",
        "alias",
        "name",
        "cellphone",
        "cellphoneExtra",
        "email",
        "employeeGroup",
        "role",
        "cellphoneIcon",
        "iceWarningIcon",
        "emailIcon",
      ],
    },
  },
  userProfileEmployeeNumber: false,
  userProfileNormalTransportKilometers: false,
  userProfileNormalTransportKilometersDefault: 0,
  userProfileNormalTransportMinutes: false,
  userProfileNormalTransportMinutesDefault: 0,
  warnIfNoWorkplace: false,
  warnOnIncompleteRouteParts: true,
  website: "",
  workDaySplitThresholdMinutes: null,
  workplaceButtonUnderCustomer: false,
  workshopCreatedByColumn: false,
  workshopCreatedDateColumn: false,
  workshopEnabled: false,
  workshopVehicleIdentificationNumber: false,
  workshopWorkTypes: [],
  workTypeAutoMachines: null,
  workTypeBeforeTimerBeforeMachinePriceItems: false,
  workTypeExtraTimers: {},
  workTypeGenericPrimaryTimerLabel: {},
  workTypeHideTimers: {},
  workTypeMachineExtraTimers: {},
  workTypePriceGroupExtraTimers: {},
  workTypePriceGroupGenericPrimaryTimerLabel: {},
  yieldLogDefaultCrop: "grass",
  yieldLogDefaultUnit: "tonne",
};
