import {ResourceInstance} from "@co-common-libs/resources";
import {identity} from "lodash";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {updateDiff} from "./actions";

export function useUpdatePropertyOnChange<
  Resource extends ResourceInstance,
  Property extends keyof Omit<Resource, "id" | "url">,
>(resource: Resource, property: Property): (value: Resource[Property]) => void {
  return useUpdatePropertyOnChangeWithTransform(resource, property, identity);
}

export function useUpdatePropertyOnChangeWithTransform<
  Resource extends ResourceInstance,
  Property extends keyof Omit<Resource, "id" | "url">,
  HandlerValue = Resource[Property],
>(
  resource: Resource,
  property: Property,
  transformation: (value: HandlerValue) => Resource[Property],
): (value: HandlerValue) => void {
  const dispatch = useDispatch();
  return useCallback(
    (value: HandlerValue) => {
      const transformedValue = transformation(value);
      if (resource[property] !== transformedValue) {
        const partial: Partial<Omit<Resource, "id" | "url">> = {};
        partial[property] = transformedValue;
        dispatch(updateDiff(partial, resource));
      }
    },
    [dispatch, resource, property, transformation],
  );
}
